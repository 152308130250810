import { graphql, PageProps, useStaticQuery } from 'gatsby'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import PDFViewer from '../../../../../components/PDFViewer'


const EULD = styled.div`
  margin: 0 auto;
  max-width: 65ch;

  p {
    margin-bottom: 1rem;
  }
`

const Title = styled.b`
  font-weight: 600;
  text-align: center;
  display: block;
  margin-bottom: 1rem;
`

const StyledSectionTitle = styled.div`
  display: flex;

  b:first-child {
    margin-right: 2rem;
  }
`

const SectionTitle: React.FC<{ index?: number; title: string }> = ({ index, title }) => {
  return (
    <StyledSectionTitle>
      {typeof index !== 'undefined' && <Title>{index}. </Title>}
      <Title>{title}</Title>
    </StyledSectionTitle>
  )
}

const StyledSection = styled.div`
  ul {
    margin-left: 2rem;
  }
`

const StyledAlphaPoint = styled.li`
  display: flex;

  p:first-child {
    margin: 0 1rem 0 2rem;
  }
`

const StyledNumberPoint = styled.li`
  display: flex;

  p:first-child {
    margin: 0 1rem;
  }
`

const AlphaPoint: React.FC<{ character: string; copy: string }> = ({ character, copy }) => {
  return (
    <StyledAlphaPoint>
      <p>({character})</p>
      <p dangerouslySetInnerHTML={{ __html: copy }} />
    </StyledAlphaPoint>
  )
}

const NumberPoint: React.FC<{ num: string; copy: string }> = ({ num, copy }) => {
  return (
    <StyledNumberPoint>
      <p>{num}.</p>
      <p dangerouslySetInnerHTML={{ __html: copy }} />
    </StyledNumberPoint>
  )
}

const TermsAndConditions: React.FC<PageProps> = props => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query TermsAndConditions {
      lockup: file(relativePath: { eq: "daisee_genesys.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pdf: file(relativePath: { eq: "EULD-daisee-Genesys.pdf" }) {
        publicURL
      }
    }
  `)

  return (
    <PDFViewer pdfPath={data.pdf.publicURL} display='none' onClose={() => history.back()} />
  )
}

export default TermsAndConditions
